import {constructor} from '../../../../mixins/constructor';

export default {
    name: "commercial",
    mixins: [constructor],
    data() {
        return {
            complexId: '',
            hoverdBuilding: '',
            selectedBuilding: '',
            hoveredBuildingElement: '',
            selectedBuildingElement: '',
            categoryList: '',
            coords: [
                '107,257,231,329,363,252,405,255,526,182,509,175,508,155,381,80,291,114,262,149,249,147,223,155,189,179,175,199,134,223,139,236',
                '645,153,845,267,1029,158,1000,142,998,106,972,85,973,80,959,73,952,78,922,61,921,52,910,47,905,44,922,35,888,13,880,17,869,12,860,20,846,15,666,119,666,141',
                '867,277,1011,191,1052,215,907,299',
                '141,466,241,523,263,511,287,526,271,541,327,574,409,526,389,513,407,499,410,492,346,456,318,472,297,455,295,443,219,399,168,429,165,451',
                '562,389,546,400,660,465,816,376,783,358,780,351,795,342,794,307,699,260,564,339',
                '705,491,863,402,914,425,917,390,906,387,931,374,947,381,949,450,1025,495,872,585',
                '184,370,373,480,455,436,266,325',
                '424,232,476,169,647,105,888,246,719,311,668,280,610,342',
                '265,324,286,297,324,264,363,240,404,226,419,227,611,339,591,345,554,355,522,372,483,404,457,436',
                '266,167,449,270,492,251,510,263,451,295,315,220,314,263,266,238',
                '503,341,657,428,853,315,856,327,655,442,502,355',
                '524,368,730,249,759,280,797,286,592,404,556,402',
                '838,314,870,346,909,350,702,474,663,469,634,437',
                '294,160,295,249,349,282,592,141,591,106,405,214,404,159,351,191',
                '801,211,801,331,827,316,841,314,854,327,855,211,829,227',
                '456,326,519,290,570,319,506,355',
                '385,291,664,132,856,240,854,341,665,234,661,196,387,351',
                '291,247,381,296,380,335,291,285',
                '591,440,619,454,677,424,676,439,625,468,590,454',
                '755,375,807,347,808,364,758,392',
                '858,247,914,217,913,294,862,323',
                '623,85,665,45,704,67,661,106',
                '726,136,752,110,774,123,748,150',
                '822,190,847,164,868,178,843,203',
                '327,248,370,225,388,231,438,234,457,244,393,284',
                '558,261,544,271,537,281,596,317,605,307,618,294',
                '507,376,602,321,613,323,521,379',
                '543,381,733,270,748,281,751,289,561,404',
                '720,515,818,457,838,464,857,475,756,534',
                '845,443,884,418,944,384,963,389,983,401,885,459',
                '434,163,455,139,481,122,521,105,559,98,578,97,587,103,606,128,593,151,563,173,530,188,487,196',
                '266,323,283,291,320,267,358,242,409,225,419,226,613,339,586,346,547,364,497,392,460,434'
            ],
            radioArr: [],
            coordsArr: []
        }
    },
    watch: {},
    created() {
    },
    mounted() {

    },
    computed: {},
    methods: {}
}
